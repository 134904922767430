import React, { Component } from "react";
import PropTypes from "prop-types";
import AccessRoles from "./AccessRoles";
import { updateCpgBrokerUserAccessRoles } from "../api/cpgBrokerUserAccessRole";
import { formattedAccessRole } from "../helpers/AccessRole";

class ConfigureCpgBrokerUserAccessRoles extends Component {
  render() {
    const {
      cpgBrokerUserId,
      fullMenuAccess,
      accessRoles,
      cpgConfiguredRoleIds,
      configuredRoleIds,
      userName,
      productAccessUrl,
      retailerAccessUrl,
      distributorAccessUrl
    } = this.props;

    return (
      <AccessRoles
        id={cpgBrokerUserId}
        fullMenuAccess={fullMenuAccess}
        accessRoles={formattedAccessRole(accessRoles, configuredRoleIds, cpgConfiguredRoleIds)}
        heading={userName}
        productAccessUrl={productAccessUrl}
        retailerAccessUrl={retailerAccessUrl}
        distributorAccessUrl={distributorAccessUrl}
        onUpdate={updateCpgBrokerUserAccessRoles}
        showFullMenuAccessCheckBox={true}
      />
    );
  }
}

ConfigureCpgBrokerUserAccessRoles.propTypes = {
  cpgBrokerUserId: PropTypes.number,
  fullMenuAccess: PropTypes.bool,
  accessRoles: PropTypes.array,
  configuredRoleIds: PropTypes.array,
  cpgConfiguredRoleIds: PropTypes.array,
  userName: PropTypes.string,
  productAccessUrl: PropTypes.string,
  retailerAccessUrl: PropTypes.string,
  distributorAccessUrl: PropTypes.string
};

export default ConfigureCpgBrokerUserAccessRoles;
