import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import FrequencyInputBox from "./FrequencyInputBox";

const Notification = ({ isCollapsed, notification, preferenceFrequencyChange }) => {
  return (
    <div className={`notification-type ${isCollapsed == true ? "hide" : ""}`}>
      <div
        className={classNames("notification-type__name", {
          disabled: notification.disabled
        })}
      >
        {notification.notificationType.name}
        {!notification.disabled && notification.notificationType.description && (
          <i
            className="fa fa-info-circle notification-type__name__description"
            aria-hidden="true"
            title={notification.notificationType.description}
          ></i>
        )}
      </div>
      <div
        className={classNames("notification-type__frequency", {
          disabled: notification.disabled
        })}
      >
        {["never", "immediately", "daily", "weekly"].map(frequency => {
          return (
            <div className="notification-type__frequency__preference" key={frequency}>
              <FrequencyInputBox
                notification={notification}
                frequency={frequency}
                preferenceFrequencyChange={preferenceFrequencyChange}
              />
            </div>
          );
        })}
      </div>
      {notification.disabled && (
        <i
          className="fa fa-info-circle notification-type__disabled-info"
          aria-hidden="true"
          title="This notification has been disabled by your administrator"
        ></i>
      )}
    </div>
  );
};

Notification.propTypes = {
  isCollapsed: PropTypes.bool,
  notification: PropTypes.object,
  preferenceFrequencyChange: PropTypes.func
};

export default Notification;
