function getDefaultOptions() {
  return {
    method: "GET",
    headers: {
      "Content-type": "application/json; charset=UTF-8"
    }
  };
}

function ajax(uri, options = {}) {
  const defaultOptions = getDefaultOptions();

  options.method = options.method ? options.method : defaultOptions.method;
  options.headers = options.headers ? options.headers : defaultOptions.headers;

  if (options.body) {
    options.body = JSON.stringify(options.body);
  }

  options.credentials = "same-origin";

  uri = uri.startsWith("/") ? uri : `/${uri}`;

  return fetch(`${uri}`, options).then(data => data.json());
}

export default ajax;
