import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const FrequencyInputBox = ({ notification, frequency, preferenceFrequencyChange }) => {
  const handleFrequencyChange = e => preferenceFrequencyChange(notification.id, e.target.value);

  return (
    <>
      <input
        type="radio"
        name={`notification[${notification.id}]frequency`}
        value={frequency}
        disabled={notification.disabled || !notification.notificationType.configurableFrequencies.includes(frequency)}
        checked={notification.frequency == frequency && !notification.disabled}
        onChange={handleFrequencyChange}
      />
      <span
        className={classNames({
          "text-muted": !notification.notificationType.configurableFrequencies.includes(frequency)
        })}
      >
        {" "}
        {frequency}{" "}
      </span>
    </>
  );
};

FrequencyInputBox.propTypes = {
  notification: PropTypes.object,
  frequency: PropTypes.string,
  preferenceFrequencyChange: PropTypes.func
};

export default FrequencyInputBox;
