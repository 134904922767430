import ajax from "../ajax.js";

export function getNotificationPreferences() {
  return ajax("api/notification_preferences", { method: "GET" });
}

export function updateNotificationPreference(params) {
  return ajax(`api/notification_preferences/${params.id}`, {
    method: "PUT",
    body: { frequency: params.frequency }
  });
}
