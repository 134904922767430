import ajax from "../ajax.js";

export function updateCpgBrokerProducts(params) {
  return ajax(`/api/company/cpg_brokers/${params.id}/update_associated_products`, {
    method: "PUT",
    body: {
      product_ids: params.productIds,
      full_product_access: params.fullProductAccess
    }
  });
}
