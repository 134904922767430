import React from "react";
import classNames from "classnames"
import DepartmentItem from "./DepartmentItem";
import { getBackgroundClass } from "../helpers/user_setting"

class DivisionItem extends React.Component {
  state = {
    isCollapsed: false
  };

  setClassName = () => {
    const { products, division } = this.props;
    const totalProducts = products.filter(product => product.division_id == division.id);
    const totalProductCount = totalProducts.length;
    const selectedProductCount = totalProducts.filter(product => product.status == "selected").length;

    return getBackgroundClass(selectedProductCount, totalProductCount);
  }

  handleBoxClick = () => this.props.divisionBoxClick(this.props.division.id)

  handleToggleClick = () => this.setState({ isCollapsed: !this.state.isCollapsed })

  render() {
    const { division, departments, categories, brands, products, departmentBoxClick, brandBoxClick, productBoxClick, categoryBoxClick } = this.props;
    const isCollapsed = this.state.isCollapsed;

    return (
      <>
        <li className="list-unstyled">
          <span className="toggle-content" onClick={this.handleToggleClick}>{isCollapsed ? "+" : "-"}</span>
          <span className={`box-fillup ${this.setClassName()}`} onClick={this.handleBoxClick}></span>
          {isCollapsed ? (
            <span>{division.name}</span>
          ) : (
            <strong>{division.name}</strong>
          )}
        </li>
        <ul className={classNames({ hide: isCollapsed })}>
          {departments.map((department) =>
            <DepartmentItem
              key={department.id}
              isCollapsed={isCollapsed}
              department={department}
              categories={categories.filter(item => item.department_id == department.id)}
              brands={brands} products={products}
              departmentBoxClick={departmentBoxClick}
              categoryBoxClick={categoryBoxClick}
              brandBoxClick={brandBoxClick}
              productBoxClick={productBoxClick}
            />
          )}
        </ul>
      </>
    );
  }
};

export default DivisionItem;
