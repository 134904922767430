import React from "react";
import PropTypes from "prop-types";
import EventRow from "./EventRow";
import OfferRow from "./OfferRow";
import MyOfferRow from "./MyOfferRow";
import { calculateGM, numberWithDelimeter, salesPerDay } from "../helpers/executive_report"

class DistributorSalesReport extends React.Component {
  state = { 
    selectedEvents: [],
    selectedOffers: [],
    selectedMyOffers: []
  }

  toggleActive = eventId => {
    let tempArray = this.state.selectedEvents;

    if(tempArray.indexOf(eventId) > -1) {
      tempArray = tempArray.filter(i => i != eventId);
    } else {
      tempArray = tempArray.concat(eventId);
    }

    this.setState({
      selectedEvents: tempArray
    })
  }

  toggleActiveOffer = offerId => {
    let tempArray = this.state.selectedOffers;

    if(tempArray.indexOf(offerId) > -1) {
      tempArray = tempArray.filter(i => i != offerId);
    } else {
      tempArray = tempArray.concat(offerId);
    }

    this.setState({
      selectedOffers: tempArray
    })
  }

  toggleActiveMyOffer = offerId => {
    let tempArray = this.state.selectedMyOffers;

    if(tempArray.indexOf(offerId) > -1) {
      tempArray = tempArray.filter(i => i != offerId);
    } else {
      tempArray = tempArray.concat(offerId);
    }

    this.setState({
      selectedMyOffers: tempArray
    })
  }

  toggleAllEvents = e => {
    const tempArray = e.target.checked ? this.props.data.map(datum => datum.id) : []

    this.setState({
      selectedEvents: tempArray
    })
  }

  toggleAllOffers = e => {
    const tempArray = e.target.checked ? this.props.offerData.map(datum => datum.id) : []

    this.setState({
      selectedOffers: tempArray
    })
  }

  toggleAllMyOffers = e => {
    const tempArray = e.target.checked ? this.props.myOffers.map(datum => datum.id) : []

    this.setState({
      selectedMyOffers: tempArray
    })
  }

  doTotal = elem => {
    let offerTotal = 0;
    let eventTotal = 0;
    let myOfferTotal = 0;

    if(this.props.print) {
      eventTotal = this.props.data.reduce((sum, datum) => sum + parseFloat(datum[elem]), 0);
      offerTotal = this.props.offerData.reduce((sum, datum) => sum + parseFloat(datum[elem]), 0);
      myOfferTotal = this.props.myOffers.reduce((sum, datum) => sum + parseFloat(datum[elem]), 0);
    } else {
      eventTotal = this.props.data.reduce((sum, datum) => sum + (this.state.selectedEvents.indexOf(datum.id) > -1 ? parseFloat(datum[elem]) : 0), 0);
      offerTotal = this.props.offerData.reduce((sum, datum) => sum + (this.state.selectedOffers.indexOf(datum.id) > -1 ? parseFloat(datum[elem]) : 0), 0);
      myOfferTotal = this.props.myOffers.reduce((sum, datum) => sum + (this.state.selectedMyOffers.indexOf(datum.id) > -1 ? parseFloat(datum[elem]) : 0), 0);
    }

    return offerTotal + eventTotal + myOfferTotal
  }

  render() {
    const { data, offerData, myOffers, print, start_on, ends_on } = this.props;

    return (
        <div className="executive-container">
          <div className="total-container">
            <table className="table table-bordered table-condensed">
              <thead>
                <tr className="highlight-row">
                  <th className="text-center total"></th>
                  <th className="text-center days-deployed">Days Deployed</th>
                  <th className="text-center grey-cell no-of-categories"># of Categories</th>
                  <th className="text-center cases-ordered">Cases Ordered</th>
                  <th className="text-center sales">$ Sales</th>
                  <th className="text-center sales-per-day">$ Sales/Day</th>
                  <th className="text-center profit">$ Profit</th>
                  <th className="text-center gm-percentage">GM%</th>
                </tr>
              </thead>
              <tbody>
                <tr className="text-right total-row bg-success">
                  <td>Total</td>
                  <td className="text-center">{this.doTotal('days_deployed')}</td>
                  <td className="grey-cell"></td>
                  <td className="text-center">{numberWithDelimeter(this.doTotal('cases_ordered'))}</td>
                  <td className="text-center">${numberWithDelimeter(this.doTotal('retailer_sales'))}</td>
                  <td className="text-center">${salesPerDay(this.doTotal('retailer_sales'), this.doTotal('days_deployed'))}</td>
                  <td className="text-center">${numberWithDelimeter(this.doTotal('retailer_profit'))}</td>
                  <td className="text-center">{calculateGM(this.doTotal('retailer_profit'), this.doTotal('retailer_sales'))}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="panel panel-info">
            <div className={`panel-body ${this.props.print ? '' : 'panel-container'}`}>
              <table className="table table-bordered table-condensed executive-offer-report">
                <thead>
                  <tr>
                    <td colSpan="9">
                      <span className="text-danger event-offer-header">Brand Offers</span>
                    </td>
                  </tr>
                  <tr className="highlight-row">
                    <th className="th-active text-center active">Active</th>
                    <th className="text-center offer">Offer</th>
                    <th className="text-center days-deployed">Days Deployed</th>
                    <th className="text-center no-of-categories"># of Categories</th>
                    <th className="text-center cases-ordered">Cases Ordered</th>
                    <th className="text-center sales">$ Sales</th>
                    <th className="text-center sales-per-day">$ Sales/Day</th>
                    <th className="text-center profit">$ Profit</th>
                    <th className="text-center gm-percentage">GM%</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan="10" className="select-all-offer">
                      <input type="checkbox" checked={offerData.length && (offerData.length === this.state.selectedOffers.length)} onChange={this.toggleAllOffers}/> &nbsp;&nbsp;Check All
                    </td>
                  </tr>
                  {
                  offerData.map((datum, idx) => <OfferRow tdData={datum} key={idx} active={(this.state.selectedOffers.indexOf(datum.id) > -1)} print={print} toggleActiveOffer={this.toggleActiveOffer} isDistribtutor= {true} />)
                  }
                </tbody>
              </table>

              <table className="table table-bordered table-condensed executive-offer-report">
                <thead>
                  <tr>
                    <td colSpan="9">
                      <span className="text-danger event-offer-header">My Offers</span>
                    </td>
                  </tr>
                  <tr className="highlight-row">
                    <th className="th-active text-center active">Active</th>
                    <th className="text-center offer">Offer</th>
                    <th className="text-center days-deployed">Days Deployed</th>
                    <th className="text-center no-of-categories"># of Categories</th>
                    <th className="text-center cases-ordered">Cases Ordered</th>
                    <th className="text-center sales">$ Sales</th>
                    <th className="text-center sales-per-day">$ Sales/Day</th>
                    <th className="text-center profit">$ Profit</th>
                    <th className="text-center gm-percentage">GM%</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan="10" className="select-all-my-offer">
                      <input type="checkbox" checked={myOffers.length && (myOffers.length === this.state.selectedMyOffers.length)} onChange={this.toggleAllMyOffers}/> &nbsp;&nbsp;Check All
                    </td>
                  </tr>
                  {
                  myOffers.map((datum, idx) => <MyOfferRow tdData={datum} key={idx} active={(this.state.selectedMyOffers.indexOf(datum.id) > -1)} print={print} toggleActiveMyOffer={this.toggleActiveMyOffer} isDistribtutor= {true} />)
                  }
                </tbody>
              </table>

              <table className="table table-bordered table-condensed executive-report">
                <thead>
                  <tr>
                    <td colSpan="6">  
                      <span className="text-danger event-offer-header">My Events</span>
                    </td>
                    <td colSpan="4" className="text-center projected-bracket-td">Projected</td>
                  </tr>
                  <tr className="highlight-row">
                    <th className="th-active text-center active">Active</th>
                    <th className="text-center event">Event</th>
                    <th className="text-center days-deployed">Days Deployed</th>
                    <th className="text-center no-of-categories"># of Categories</th>
                    <th className="text-center cases-ordered">Cases Ordered</th>
                    <th className="text-center sales">$ Sales</th>
                    <th className="text-center sales-per-day">$ Sales/Day</th>
                    <th className="text-center profit">$ Profit</th>
                    <th className="text-center gm-percentage">GM%</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan="10" className="select-all-event">
                      <input type="checkbox" checked={data.length && (data.length === this.state.selectedEvents.length)} onChange={this.toggleAllEvents}/> &nbsp;&nbsp;Check All
                    </td>
                  </tr>
                  {
                  data.map((datum, idx) => <EventRow tdData={datum} key={idx} active={(this.state.selectedEvents.indexOf(datum.id) > -1)} print={print} toggleActive={this.toggleActive} />)
                  }
                </tbody>
              </table>
            </div>
          </div>

          <div className="print-btn-container">
            {print ? null : <a href={`/distributor/reports/print_executive?start_on=${start_on}&ends_on=${ends_on}&event_ids=${this.state.selectedEvents}&offer_ids=${this.state.selectedOffers}&my_offer_ids=${this.state.selectedMyOffers}&print=${print}`} className="btn btn-primary" target="_blank">Print</a>}
          </div>
        </div>
    )
  }
};

DistributorSalesReport.propTypes = {
  data: PropTypes.array,
  offerData: PropTypes.array,
  print: PropTypes.bool
}

export default DistributorSalesReport;
