import React from "react";
import PropTypes from "prop-types";
import CpgProductAccess from "./CpgProductAccess";
import { updateUserProducts } from "../api/userProduct";

class ConfigureUserProducts extends React.Component {
  render() {
    const {
      userId,
      fullProductAccess,
      products,
      divisions,
      departments,
      categories,
      brands,
      userName
    } = this.props;

    return (
      <CpgProductAccess
        id={userId}
        fullProductAccess={fullProductAccess}
        scope = {"User Name:"}
        scopeName = {userName}
        products = {products}
        divisions = {divisions}
        categories = {categories}
        departments = {departments}
        brands = {brands}
        onUpdate={updateUserProducts}
        showLinks={false}
        showName={true}
        offset= {"col-sm-offset-1"}
      />
    );
  }
}

ConfigureUserProducts.propTypes = {
  UserId: PropTypes.number,
  fullProductAccess: PropTypes.bool,
  divisions: PropTypes.array,
  departments: PropTypes.array,
  categories: PropTypes.array,
  brands: PropTypes.array,
  products: PropTypes.array
}

export default ConfigureUserProducts;
