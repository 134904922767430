import React from "react";
import classNames from "classnames"
import CategoryItem from "./CategoryItem";
import { getBackgroundClass } from "../helpers/user_setting"

class DepartmentItem extends React.Component {
  state = {
    isCollapsed: false
  }

  setClassName = () => {
    const { products, department } = this.props;
    const totalProducts = products.filter(product => product.department_id == department.id);
    const totalProductCount = totalProducts.length;
    const selectedProductCount = totalProducts.filter(product => product.status == "selected").length;

    return getBackgroundClass(selectedProductCount, totalProductCount);
  }

  handleBoxClick = () => this.props.departmentBoxClick(this.props.department.id);

  handleToggleClick = () => this.setState({ isCollapsed: !this.state.isCollapsed })

  render() {
    const { department, categories, brands, products, categoryBoxClick, brandBoxClick, productBoxClick } = this.props
    const isCollapsed = this.state.isCollapsed;

    return (
      <>
        <li className="list-unstyled">
          <span className="toggle-content" onClick={this.handleToggleClick}>{isCollapsed ? "+" : "-"}</span>
          <span className={`box-fillup ${this.setClassName()}`} onClick={this.handleBoxClick}></span>
          {isCollapsed ? (
            <span>{department.name}</span>
          ) : (
            <strong>{department.name}</strong>
          )}
        </li>
        <ul className={classNames({ hide: isCollapsed })}>
          {categories.map((category) =>
            <CategoryItem
              key={category.id}
              isCollapsed={isCollapsed}
              category={category}
              brands={brands.filter(item => item.category_id == category.id)}
              products={products}
              categoryBoxClick={categoryBoxClick}
              brandBoxClick={brandBoxClick}
              productBoxClick={productBoxClick}
            />
          )}
        </ul>
      </>
    );
  }
};

export default DepartmentItem;
