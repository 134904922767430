import React from "react";
import PropTypes from "prop-types";
import CpgProductAccess from "./CpgProductAccess";
import { updateCpgBrokerUserProducts } from "../api/cpgBrokerUserProduct";

class ConfigureCpgBrokerUserProducts extends React.Component {
  render() {
    const {
      cpgBrokerUserId,
      fullProductAccess,
      products,
      divisions,
      departments,
      categories,
      brands,
      userName,
      menuAccessUrl,
      retailerAccessUrl,
      distributorAccessUrl
    } = this.props;

    return (
      <CpgProductAccess
        id={cpgBrokerUserId}
        fullProductAccess={fullProductAccess}
        heading={userName}
        products={products}
        divisions={divisions}
        categories={categories}
        departments={departments}
        brands={brands}
        menuAccessUrl={menuAccessUrl}
        retailerAccessUrl={retailerAccessUrl}
        distributorAccessUrl={distributorAccessUrl}
        onUpdate={updateCpgBrokerUserProducts}
        showLinks={true}
        showName={false}
        offset={""}
      />
    );
  }
}

ConfigureCpgBrokerUserProducts.propTypes = {
  cpgBrokerUserId: PropTypes.number,
  fullProductAccess: PropTypes.bool,
  divisions: PropTypes.array,
  departments: PropTypes.array,
  categories: PropTypes.array,
  brands: PropTypes.array,
  userName: PropTypes.string,
  products: PropTypes.array,
  menuAccessUrl: PropTypes.string,
  retailerAccessUrl: PropTypes.string,
  distributorAccessUrl: PropTypes.string
};

export default ConfigureCpgBrokerUserProducts;
