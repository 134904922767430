import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import groupBy from "lodash/groupBy";
import isEqual from "lodash/isEqual";
import uniq from "lodash/uniq";
import difference from "lodash/difference";

class AccessRoles extends Component {
  constructor(props) {
    super(props);
    const { accessRoles, fullMenuAccess } = this.props;

    this.state = {
      accessRoleIds: accessRoles.filter(({ access }) => access === true).map(role => role.id),
      lastSavedAccessRoleIds: accessRoles.filter(role => role.access == true).map(({ id }) => id),
      fullMenuAccess: fullMenuAccess,
      showSuccessMessage: false,
      responseMessage: "",
      showErrorMessage: false
    };
  }

  checkIfFullMenuAccess = () => {
    if (this.state.fullMenuAccess) {
      this.setState({
        showErrorMessage: true
      });

      return true;
    }
  };

  addOrRemoveIdFromArray = (array, id) => (array.includes(id) ? array.filter(i => i !== id) : array.concat(id));

  handleGroupRoleClick = (e, groupName) => {
    if (!this.checkIfFullMenuAccess()) {
      const { accessRoleIds } = this.state;
      const { accessRoles } = this.props;

      const isChecked = e.target.checked;
      const groupAccessRoleIds = accessRoles.filter(({ group_name }) => group_name === groupName).map(({ id }) => id);

      // appends ids when checked and removes when unchecked
      const updateAccessRoleIds = isChecked
        ? uniq([...accessRoleIds, ...groupAccessRoleIds])
        : difference(accessRoleIds, groupAccessRoleIds);
      this.setState({ accessRoleIds: updateAccessRoleIds });
    }
  };

  handleIndividualRoleClick = ({ target: { id } }) => {
    if (!this.checkIfFullMenuAccess()) {
      this.setState(({ accessRoleIds }) => ({
        accessRoleIds: this.addOrRemoveIdFromArray(accessRoleIds, parseInt(id, 10))
      }));
    }
  };

  handleUpdate = () => {
    const { id, onUpdate } = this.props;
    const { fullMenuAccess, accessRoleIds } = this.state;

    onUpdate({ id, fullMenuAccess, accessRoleIds })
      .then(response => {
        this.setState(({ accessRoleIds }) => ({
          showSuccessMessage: true,
          responseMessage: response.message,
          lastSavedAccessRoleIds: accessRoleIds
        }));
      })
      .then(() => setTimeout(() => this.setState({ showSuccessMessage: false }), 3000));
  };

  handleLinkClick = e => {
    const { accessRoleIds: currentlyCheckedAccessRoleIds, lastSavedAccessRoleIds } = this.state;

    if (!isEqual([...currentlyCheckedAccessRoleIds].sort(), [...lastSavedAccessRoleIds].sort())) {
      const warningMessage =
        "You are about to navigate to another page without saving your changes, click 'Cancel', update the page and then navigate to other pages";

      if (!confirm(warningMessage)) {
        e.preventDefault();
      }
    }
  };

  handleFullMenuAccessClick = () => {
    this.setState(({ fullMenuAccess }) => ({
      fullMenuAccess: !fullMenuAccess,
      accessRoleIds: this.props.accessRoles.filter(({ disabled }) => !disabled).map(({ id }) => id),
      showErrorMessage: false
    }));
  };

  getGroups = accessRoles => groupBy(accessRoles, "group_name");

  getColumnData = groupNames => [
    groupNames["Offers"],
    groupNames["Products"],
    groupNames["LQD"],
    groupNames["Prospecting"],
    groupNames["Events"],
    groupNames["Reports"]
  ];

  renderCell = (column, i) => {
    return (
      column[i] && (
        <Fragment>
          <div className={column[i].disabled ? "text-muted" : ""}>{column[i].name}</div>
          <input
            id={column[i].id}
            type="checkbox"
            disabled={column[i].disabled}
            checked={!column[i].disabled && this.state.accessRoleIds.includes(column[i].id)}
            onChange={this.handleIndividualRoleClick}
          />
        </Fragment>
      )
    );
  };

  render() {
    const { accessRoleIds, showSuccessMessage, responseMessage } = this.state;
    const {
      heading,
      productAccessUrl,
      retailerAccessUrl,
      distributorAccessUrl,
      showFullMenuAccessCheckBox
    } = this.props;

    let max = 0;
    const accessRoleGroups = this.getGroups(this.props.accessRoles);

    Object.values(accessRoleGroups).forEach(key => (max = key.length > max ? key.length : max));

    const tableRows = [];
    const columnData = this.getColumnData(accessRoleGroups);

    for (let i = 0; i < max; i++) {
      let rowData = (
        <Fragment>
          <tr>
            {[0, 1, 2, 3, 4, 5].map(index => (
              <td key={index}>{this.renderCell(columnData[index], i)}</td>
            ))}
          </tr>
        </Fragment>
      );

      tableRows.push(rowData);
    }

    return (
      <Fragment>
        {showSuccessMessage && responseMessage && (
          <div className="alert alert-success broker-access-update-alert">{responseMessage}</div>
        )}
        {this.state.showErrorMessage && (
          <div className="alert alert-danger">
            Please uncheck full menu access to manually associate menu access to user.
          </div>
        )}
        <div className="broker-access-control-heading-section">
          <h3 className="form-breadcrumb">{heading}</h3>
          <div>
            <a href="/company/users" className="btn btn-default">
              Cancel
            </a>
            <a href={productAccessUrl} className="btn btn-primary" onClick={this.handleLinkClick}>
              Product Access
            </a>
            <a href={retailerAccessUrl} className="btn btn-primary" onClick={this.handleLinkClick}>
              Retailer Access
            </a>
            <a href={distributorAccessUrl} className="btn btn-primary" onClick={this.handleLinkClick}>
              Distributor Access
            </a>
            <button className="btn btn-primary" onClick={this.handleUpdate}>
              Update
            </button>
          </div>
        </div>
        {showFullMenuAccessCheckBox && (
          <div>
            <label>
              <input type="checkbox" checked={this.state.fullMenuAccess} onChange={this.handleFullMenuAccessClick} />
              <span> Always keep full menu access</span>
            </label>
          </div>
        )}
        <table className="table table-bordered cpg-broker-menu-access-table">
          <thead>
            <tr className="bg-success">
              {Object.keys(accessRoleGroups).map(groupName => (
                <th key={groupName}>
                  <div className={accessRoleGroups[groupName].some(({ disabled }) => disabled) ? "text-muted" : ""}>
                    {groupName}
                  </div>
                  <input
                    type="checkbox"
                    disabled={accessRoleGroups[groupName].some(({ disabled }) => disabled)}
                    checked={
                      accessRoleGroups[groupName].every(({ disabled }) => !disabled) &&
                      accessRoleGroups[groupName].every(({ id }) => accessRoleIds.includes(id))
                    }
                    onChange={e => this.handleGroupRoleClick(e, groupName)}
                  />
                </th>
              ))}
            </tr>
          </thead>
          <tbody>{tableRows}</tbody>
        </table>
        <button className="btn btn-primary pull-right" onClick={this.handleUpdate}>
          Update
        </button>
      </Fragment>
    );
  }
}

export default AccessRoles;

AccessRoles.propTypes = {
  accessRoles: PropTypes.array,
  id: PropTypes.number,
  fullMenuAccess: PropTypes.bool,
  onUpdate: PropTypes.func,
  heading: PropTypes.string,
  productAccessUrl: PropTypes.string,
  retailerAccessUrl: PropTypes.string,
  showFullMenuAccessCheckBox: PropTypes.bool
};
