import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";

class CompanyAccess extends Component {
  constructor(props) {
    super(props);
    const { companies, fullCompanyAccess } = this.props;
    const configuredCompanyIds = companies.filter(({ access }) => access === true).map(({ id }) => id);

    this.state = {
      companyIds: configuredCompanyIds,
      lastSavedCompanyIds: configuredCompanyIds,
      fullCompanyAccess: fullCompanyAccess,
      showSuccessMessage: false,
      responseMessage: "",
      showErrorMessage: false
    };
  }

  checkIfFullCompanyAccess = () => {
    if (this.state.fullCompanyAccess) {
      this.setState({
        showErrorMessage: true
      });

      return true;
    }
  };

  addOrRemoveIdFromArray = (array, id) => (array.includes(id) ? array.filter(i => i !== id) : array.concat(id));

  handleCompanyClick = ({ target: { id } }) => {
    if (!this.checkIfFullCompanyAccess()) {
      this.setState(({ companyIds }) => ({
        companyIds: this.addOrRemoveIdFromArray(companyIds, parseInt(id, 10))
      }));
    }
  };

  handleUpdate = () => {
    const { id, onUpdate, companyType } = this.props;
    const { fullCompanyAccess, companyIds } = this.state;

    onUpdate({ id, fullCompanyAccess, companyIds, companyType })
      .then(response => {
        this.setState(({ companyIds }) => ({
          showSuccessMessage: true,
          responseMessage: response.message,
          lastSavedCompanyIds: companyIds
        }));
      })
      .then(() => setTimeout(() => this.setState({ showSuccessMessage: false }), 3000));
  };

  handleLinkClick = e => {
    const { companyIds: currentlyCheckedCompanyIds, lastSavedCompanyIds } = this.state;

    if (!isEqual([...currentlyCheckedCompanyIds].sort(), [...lastSavedCompanyIds].sort())) {
      const warningMessage =
        "You are about to navigate to another page without saving your changes, click 'Cancel', update the page and then navigate to other pages";

      if (!confirm(warningMessage)) {
        e.preventDefault();
      }
    }
  };

  handleFullCompanyAccessClick = () => {
    this.setState(({ fullCompanyAccess }) => ({
      fullCompanyAccess: !fullCompanyAccess,
      companyIds: this.props.companies.map(({ id }) => id),
      showErrorMessage: false
    }));
  };

  render() {
    const { companyIds, showSuccessMessage, responseMessage, fullCompanyAccess } = this.state;
    const {
      companies,
      heading,
      companyType,
      companyAccessUrl,
      productAccessUrl,
      menuAccessUrl,
      showFullCompanyAccessCheckBox
    } = this.props;

    return (
      <Fragment>
        {showSuccessMessage && responseMessage && (
          <div className="alert alert-success broker-access-update-alert">{responseMessage}</div>
        )}
        {this.state.showErrorMessage && (
          <div className="alert alert-danger">
            Please uncheck full {companyType.toLowerCase()} access to manually associate {companyType.toLowerCase()}s to
            user.
          </div>
        )}
        <div className="broker-access-control-heading-section">
          <h3 className="form-breadcrumb">{heading}</h3>
          <div>
            <a href="/company/users" className="btn btn-default">
              Cancel
            </a>
            <a href={productAccessUrl} className="btn btn-primary" onClick={this.handleLinkClick}>
              Product Access
            </a>
            <a href={menuAccessUrl} className="btn btn-primary" onClick={this.handleLinkClick}>
              Menu Access
            </a>
            <a href={companyAccessUrl} className="btn btn-primary" onClick={this.handleLinkClick}>
              {companyType == "Retailer" ? "Dist. Access" : "Retailer Access"}
            </a>
            <button className="btn btn-primary" onClick={this.handleUpdate}>
              Update
            </button>
          </div>
        </div>
        {showFullCompanyAccessCheckBox && (
          <div>
            <label>
              <input type="checkbox" checked={fullCompanyAccess} onChange={this.handleFullCompanyAccessClick} />
              <span> Always keep full {companyType.toLowerCase()} access</span>
            </label>
          </div>
        )}
        <table className="table table-bordered cpg-broker-companies-table">
          <thead>
            <tr>
              <th className="access">Access</th>
              <th className="logo"></th>
              <th className="company-name">Company</th>
            </tr>
          </thead>
          <tbody>
            {companies.map(company => (
              <tr key={company.id}>
                <td>
                  <input
                    type="checkbox"
                    id={company.id}
                    checked={companyIds.includes(company.id)}
                    onChange={this.handleCompanyClick}
                  />
                </td>
                <td className="logo">
                  <img src={company.image_url_thumb} alt="Logo"></img>
                </td>
                <td className="company-name">{company.name}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <button className="btn btn-primary pull-right" onClick={this.handleUpdate}>
          Update
        </button>
      </Fragment>
    );
  }
}

export default CompanyAccess;

CompanyAccess.propTypes = {
  companies: PropTypes.array,
  id: PropTypes.number,
  fullCompanyAccess: PropTypes.bool,
  onUpdate: PropTypes.func,
  heading: PropTypes.string,
  companyType: PropTypes.string,
  companyAccessUrl: PropTypes.string,
  productAccessUrl: PropTypes.string,
  menuAccessUrl: PropTypes.string,
  showFullCompanyAccessCheckBox: PropTypes.bool
};
