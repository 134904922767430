import React from "react";
import PropTypes from "prop-types";
import CpgProductAccess from "./CpgProductAccess";
import { updateCpgBrokerProducts } from "../api/cpgBrokerProduct";

class ConfigureCpgBrokerProducts extends React.Component {
  render() {
    const {
      cpgBrokerId,
      fullProductAccess,
      products,
      divisions,
      departments,
      categories,
      brands,
      brokerName,
      menuAccessUrl,
      retailerAccessUrl,
      distributorAccessUrl
    } = this.props;

    return (
      <CpgProductAccess
        id={cpgBrokerId}
        fullProductAccess={fullProductAccess}
        heading={"Broker Product Access"}
        scope={"Broker Name:"}
        scopeName={brokerName}
        products={products}
        divisions={divisions}
        categories={categories}
        departments={departments}
        brands={brands}
        menuAccessUrl={menuAccessUrl}
        retailerAccessUrl={retailerAccessUrl}
        distributorAccessUrl={distributorAccessUrl}
        onUpdate={updateCpgBrokerProducts}
        showLinks={true}
        showName={true}
        offset={"col-sm-offset-1"}
      />
    );
  }
}

ConfigureCpgBrokerProducts.propTypes = {
  cpgBrokerId: PropTypes.number,
  fullProductAccess: PropTypes.bool,
  divisions: PropTypes.array,
  departments: PropTypes.array,
  categories: PropTypes.array,
  brands: PropTypes.array,
  brokerName: PropTypes.string,
  products: PropTypes.array,
  menuAccessUrl: PropTypes.string,
  retailerAccessUrl: PropTypes.string,
  distributorAccessUrl: PropTypes.string
};

export default ConfigureCpgBrokerProducts;
