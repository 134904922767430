import React, { Component } from "react";
import PropTypes from "prop-types";
import AccessRoles from "./AccessRoles";
import { updateCpgBrokerAccessRoles } from "../api/cpgBrokerAccessRole";
import { formattedAccessRole } from "../helpers/AccessRole";

class ConfigureCpgBrokerAccessRoles extends Component {
  render() {
    const {
      cpgBrokerId,
      fullMenuAccess,
      accessRoles,
      configuredRoleIds,
      productAccessUrl,
      retailerAccessUrl,
      distributorAccessUrl
    } = this.props;

    return (
      <AccessRoles
        id={cpgBrokerId}
        fullMenuAccess={fullMenuAccess}
        accessRoles={formattedAccessRole(accessRoles, configuredRoleIds)}
        heading={"Broker Menu Access"}
        productAccessUrl={productAccessUrl}
        retailerAccessUrl={retailerAccessUrl}
        distributorAccessUrl={distributorAccessUrl}
        onUpdate={updateCpgBrokerAccessRoles}
        showFullMenuAccessCheckBox={false}
      />
    );
  }
}

ConfigureCpgBrokerAccessRoles.propTypes = {
  fullMenuAccess: PropTypes.bool,
  accessRoles: PropTypes.array,
  configuredRoleIds: PropTypes.array,
  cpgBrokerId: PropTypes.number,
  productAccessUrl: PropTypes.string,
  retailerAccessUrl: PropTypes.string,
  distributorAccessUrl: PropTypes.string
};

export default ConfigureCpgBrokerAccessRoles;
