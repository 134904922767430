import React from "react";
import { calculateGM, numberWithDelimeter, salesPerDay } from "../helpers/executive_report"

class EventRow extends React.Component {
  setClassName = () => {
    return (this.props.print || this.props.active) ? 'event-row' : 'text-muted event-row';
  }

  setEventLink = () => {
    if(this.props.print) {
      return this.props.tdData.title;
    } else if(this.props.isCpg) {
      return <a href={`/received_events/${this.props.tdData.id}`}>{this.props.tdData.title}</a>;
    } else {
      return <a href={`/retailer/events/${this.props.tdData.id}`}>{this.props.tdData.title}</a>;
    }
  }

  toggleActive = () => this.props.toggleActive(this.props.tdData.id)

  render() {
    return (
        <tr className={this.setClassName()}>
          <td className="td-active text-center"><input type="checkbox" checked={this.props.active} onChange={this.toggleActive}/></td>
          <td className="event-title text-left">{this.setEventLink()}</td>
          <td className="days-deployed text-center">{this.props.tdData.days_deployed}</td>
          <td className="category-count text-center">{this.props.tdData.category_count}</td>
          <td className="cases-ordered text-center">{numberWithDelimeter(this.props.tdData.cases_ordered)}</td>
          <td className="sales text-center">${numberWithDelimeter(this.props.tdData.retailer_sales)}</td>
          <td className="sales-per-day text-center">${salesPerDay(this.props.tdData.retailer_sales, this.props.tdData.days_deployed)}</td>
          <td className="profit text-center">${numberWithDelimeter(this.props.tdData.retailer_profit)}</td>
          <td className="gm-percentage text-center">{calculateGM(this.props.tdData.retailer_profit, this.props.tdData.retailer_sales)}</td>
        </tr>
    )
  }
};

export default EventRow;
