import React from "react";
import PropTypes from "prop-types";
import uniq from "lodash/uniq";
import isEqual from "lodash/isEqual"
import DivisionItem from "./DivisionItem";

class CpgProductAccess extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      products: this.props.products,
      updatedProductIds: this.props.products.filter(product => product.status == "selected").map(product => product.id),
      fullProductAccess: this.props.fullProductAccess,
      updatedFullProductAccess: this.props.fullProductAccess,
      showError: false,
      showSuccessMessage: false,
      responseMessage: ""
    };
  }

  checkIfFullProductAccess = () => {
    if(this.state.fullProductAccess){
      this.setState({
        showError: true
      })
      return true;
    }
  }

  handleDivisionBoxClick = id => {
    if(!this.checkIfFullProductAccess()) {
      const products = this.state.products;
      const productStatusValues = products.filter(product => product.division_id === id).map(product => product.status)
      const changedProductStatus = this.getChangedStatus(productStatusValues)
      const productData = products.map(product => product.division_id === id ? {...product, status: changedProductStatus} : product)

      this.setState({
        products: productData
      })
    }
  }

  handleDepartmentBoxClick = id => {
    if(!this.checkIfFullProductAccess()) {
      const products = this.state.products;
      const productStatusValues = products.filter(product => product.department_id === id).map(product => product.status)
      const changedProductStatus = this.getChangedStatus(productStatusValues)
      const productData = products.map(product => product.department_id === id ? {...product, status: changedProductStatus} : product)

      this.setState({
        products: productData
      })
    }
  }

  handleCategoryBoxClick = id => {
    if(!this.checkIfFullProductAccess()) {
      const products = this.state.products;
      const productStatusValues = products.filter(product => product.category_id === id).map(product => product.status)
      const changedProductStatus = this.getChangedStatus(productStatusValues)
      const productData = products.map(product => product.category_id === id ? {...product, status: changedProductStatus} : product)

      this.setState({
        products: productData
      })
    }
  }

  handleBrandBoxClick = id => {
    if(!this.checkIfFullProductAccess()) {
      const products = this.state.products;
      const productStatusValues = products.filter(product => product.brand_id === id).map(product => product.status)
      const changedProductStatus = this.getChangedStatus(productStatusValues)
      const productData = products.map(product => product.brand_id === id ? { ...product, status: changedProductStatus } : product)

      this.setState({
        products: productData
      })
    }
  }

  handleProductBoxClick = id => {
    if(!this.checkIfFullProductAccess()) {
      const products = this.state.products.map(product => product.id === id ? {...product, status: this.changeStatus(product.status)} : product)

      this.setState({ products })
    }
  }

  changeStatus = status => status === "not selected" ? "selected" : "not selected"

  getChangedStatus = statusValues => {
    const uniqStatus = uniq(statusValues);
    let currentStatus = "";

    if(uniqStatus.length > 1) {
      currentStatus = "partially selected"
    } else if(uniqStatus[0] === "selected") {
      currentStatus = "selected"
    } else if (uniqStatus[0] === "partially selected") {
      currentStatus = "partially selected"
    } else {
      currentStatus = "not selected"
    }

    return this.changeStatus(currentStatus)
  }

  updateAssociatedProducts = () => {
    const id = this.props.id;
    const productIds = this.state.products.filter(product => product.status == "selected").map(product => product.id);
    const fullProductAccess = this.state.fullProductAccess;
    this.props.onUpdate({ id, productIds, fullProductAccess })
    .then((data) => {
      this.setState({
        showSuccessMessage: true,
        updatedProductIds: this.state.products.filter(product => product.status == "selected").map(product => product.id),
        responseMessage: data.message,
        updatedFullProductAccess: this.state.fullProductAccess
      })
    })
    .then(() => setTimeout(() => this.setState({ showSuccessMessage: false }), 3000))
  }

  handleCheckBoxChange = () => {
    this.setState({
      fullProductAccess: !this.state.fullProductAccess,
      products: this.state.products.map(product => ({...product, status: "selected"})),
      showError: false
    })
  }

  handleLinkClick = (e) => {
    if(!isEqual(this.state.products.filter(product => product.status == "selected").map(product => product.id), this.state.updatedProductIds) || this.state.fullProductAccess != this.state.updatedFullProductAccess) {
      if(!confirm("You are about to navigate to another page without saving your changes, click 'Cancel', update the page and then navigate to other pages")) {
        e.preventDefault();
      }
    }
  }

  render() {
    const {
      divisions,
      departments,
      categories,
      brands,
      heading,
      scope,
      scopeName,
      menuAccessUrl,
      retailerAccessUrl,
      distributorAccessUrl,
      showLinks,
      showName,
      offset
    } = this.props;

    return (
      <>
        {this.state.showSuccessMessage && this.state.responseMessage && <div className="alert alert-success broker-access-update-alert">{this.state.responseMessage}</div>}
        {this.state.showError && <div className="alert alert-danger broker-access-update-alert">Please uncheck full product access to manually associate products to broker.</div>}
        {showLinks && (
          <div className="broker-access-control-heading-section">
            <h3 className="form-breadcrumb">{heading}</h3>
            <div>
              <a href="/company/users" className="btn btn-default">
                Cancel
              </a>
              <a href={menuAccessUrl} className="btn btn-primary" onClick={this.handleLinkClick}>
                Menu Access
              </a>
              <a href={retailerAccessUrl} className="btn btn-primary" onClick={this.handleLinkClick}>
                Retailer Access
              </a>
              <a href={distributorAccessUrl} className="btn btn-primary" onClick={this.handleLinkClick}>
                Dist. Access
              </a>
              <button className="btn btn-primary" onClick={this.updateAssociatedProducts}>
                Update
              </button>
            </div>
          </div>
        )}
        <div className="row">
          {showName && (
            <div className="col-sm-5">
              <strong>{scope} </strong>{scopeName}
            </div>
          )}
          <div className={`col-sm-4 ${offset}`}>
            <label>
              <input
                type="checkbox"
                checked={this.state.fullProductAccess}
                onChange={this.handleCheckBoxChange}
                className="grant-full-product-access-checkbox"
              />
              <span> Always keep full product access</span>
            </label>
          </div>
        </div>
        <div className="product-selection-hierarchy">Division | Department | Category | Brand | Product</div>

        <ul className="user-product-list">
          {divisions.map((division) =>
          <DivisionItem
            key={division.id}
            division={division}
            departments={departments.filter(item => item.division_id == division.id)}
            categories={categories}
            brands={brands}
            products={this.state.products}
            divisionBoxClick={this.handleDivisionBoxClick}
            departmentBoxClick={this.handleDepartmentBoxClick}
            categoryBoxClick={this.handleCategoryBoxClick}
            brandBoxClick={this.handleBrandBoxClick}
            productBoxClick={this.handleProductBoxClick}
          />
        )}
        </ul>
        <button className="btn btn-primary" onClick={this.updateAssociatedProducts}>Update</button>
      </>
    )
  }
};

CpgProductAccess.propTypes = {
  products: PropTypes.array,
  id: PropTypes.number,
  fullProductAccess: PropTypes.bool,
  onUpdate: PropTypes.func,
  heading: PropTypes.string,
  divisions: PropTypes.array,
  departments: PropTypes.array,
  categories: PropTypes.array,
  brands: PropTypes.array,
  menuAccessUrl: PropTypes.string,
  retailerAccessUrl: PropTypes.string,
  distributorAccessUrl: PropTypes.string
};

export default CpgProductAccess;
