import ajax from "../ajax.js";

export function updateUserProducts(params) {
  return ajax(`/api/company/users/${params.id}`, {
    method: 'PUT',
    body: {
      product_ids: params.productIds,
      full_product_access: params.fullProductAccess
    }
  })
}
