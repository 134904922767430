import React from "react";
import classNames from "classnames"
import CompanyProductItem from "./CompanyProductItem";
import { getBackgroundClass } from "../helpers/user_setting"

class BrandItem extends React.Component {
  state = {
    isCollapsed: false,
  }

  setClassName = () => {
    const products = this.props.products;
    const totalProductCount = products.length;
    const selectedProductCount = products.filter(product => product.status == "selected").length;
    return getBackgroundClass(selectedProductCount, totalProductCount);
  }

  handleBoxClick = () => this.props.brandBoxClick(this.props.brand.id);

  handleToggleClick = () => this.setState({ isCollapsed: !this.state.isCollapsed })

  render() {
    const { brand, products, productBoxClick } = this.props;
    const isCollapsed = this.state.isCollapsed;

    return (
      <>
        <li className="list-unstyled">
          <span className="toggle-content" onClick={this.handleToggleClick}>{isCollapsed ? "+" : "-"}</span>
          <span className={`box-fillup ${this.setClassName()}`} onClick={this.handleBoxClick}></span>
          {isCollapsed ? (
            <span>{brand.name}</span>
          ) : (
            <strong>{brand.name}</strong>
          )}
        </li>
        <ul className={classNames({ hide: isCollapsed })}>
          {products.map(product =>
            <CompanyProductItem
              key={product.id}
              isCollapsed={isCollapsed}
              product={product}
              productBoxClick={productBoxClick}
            />
          )}
        </ul>
      </>
    );
  }
};

export default BrandItem;
