import React from "react";
import classNames from "classnames"
import BrandItem from "./BrandItem";
import { getBackgroundClass } from "../helpers/user_setting"

class CategoryItem extends React.Component {
  state = {
    isCollapsed: false
  }

  setClassName = () => {
    const { products, category } = this.props;
    const totalProducts = products.filter(product => product.category_id == category.id);
    const totalProductCount = totalProducts.length;
    const selectedProductCount = totalProducts.filter(product => product.status == "selected").length;

    return getBackgroundClass(selectedProductCount, totalProductCount);
  }

  handleBoxClick = () => this.props.categoryBoxClick(this.props.category.id);

  handleToggleClick = () => this.setState({ isCollapsed: !this.state.isCollapsed })

  render() {
    const { category, brands, products, brandBoxClick, productBoxClick } = this.props;
    const isCollapsed = this.state.isCollapsed;

    return (
      <>
        <li className="list-unstyled">
          <span className="toggle-content" onClick={this.handleToggleClick}>{isCollapsed ? "+" : "-"}</span>
          <span className={`box-fillup ${this.setClassName()}`} onClick={this.handleBoxClick}></span>
          {isCollapsed ? (
            <span>{category.name}</span>
          ) : (
            <strong>{category.name}</strong>
          )}
        </li>
        <ul className={classNames({ hide: isCollapsed })}>
          {brands.map((brand) =>
            <BrandItem
              key={brand.id}
              isCollapsed={isCollapsed}
              brand={brand}
              products={products.filter(item => item.brand_id == brand.id)}
              brandBoxClick={brandBoxClick}
              productBoxClick={productBoxClick}
            />
          )}
        </ul>
      </>
    );
  }
};

export default CategoryItem;
