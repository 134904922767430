import React from "react";

class CompanyProductItem extends React.Component {
  setClassName() {
    if(this.props.product.status == "selected") {
      return "black-background"
    }

    return "white-background";
  }

  handleBoxClick = () => this.props.productBoxClick(this.props.product.id);

  render() {
    const product = this.props.product;

    return (
      <li className="list-unstyled">
        <span className={`box-fillup ${this.setClassName()}`} onClick={this.handleBoxClick}></span>
        <span className="cpg-product-label">[{product.universal_product_code}] {product.material_description}</span>
      </li>
    );
  }
};

export default CompanyProductItem;
