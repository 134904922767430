import React, { useState } from "react";
import PropTypes from "prop-types";
import Notification from "./Notification";
import classNames from "classnames";

const GroupedNotifications = ({ name, notificationPreferences, preferenceFrequencyChange }) => {
  const [isCollapsed, setIsToggled] = useState(false);

  const handleToggleClick = () => setIsToggled(!isCollapsed);

  return (
    <div>
      <h4 className="notification-heading" onClick={handleToggleClick}>
        <i
          className={classNames("fa", { "fa-caret-right": isCollapsed }, { "fa-caret-down": !isCollapsed })}
          aria-hidden="true"
        ></i>
        {name}
      </h4>
      {notificationPreferences.map(notification => (
        <Notification
          key={notification.id}
          isCollapsed={isCollapsed}
          notification={notification}
          preferenceFrequencyChange={preferenceFrequencyChange}
        />
      ))}
    </div>
  );
};

GroupedNotifications.propTypes = {
  name: PropTypes.string,
  notificationPreferences: PropTypes.array,
  preferenceFrequencyChange: PropTypes.func
};

export default GroupedNotifications;
