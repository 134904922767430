import ajax from "../ajax.js";

export function updateCpgBrokerUserCompanies(params) {
  return ajax(`api/company/cpg_broker_users/${params.id}`, {
    method: "PUT",
    body: {
      full_company_access: params.fullCompanyAccess,
      company_ids: params.companyIds,
      company_type: params.companyType
    }
  });
}
