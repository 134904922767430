import React, { Component } from "react";
import PropTypes, { string } from "prop-types";
import CompanyAccess from "./CompanyAccess";
import { updateCpgBrokerCompanies } from "../api/cpgBrokerCompanies";
import { formattedCompanies } from "../helpers/CompanyAccess";

class ConfigureCpgBrokerCompanyAccess extends Component {
  render() {
    const {
      cpgBrokerId,
      companies,
      fullCompanyAccess,
      heading,
      companyType,
      configuredCompanyIds,
      companyAccessUrl,
      productAccessUrl,
      menuAccessUrl
    } = this.props;

    return (
      <CompanyAccess
        id={cpgBrokerId}
        fullCompanyAccess={fullCompanyAccess}
        companies={formattedCompanies(companies, configuredCompanyIds)}
        heading={heading}
        companyType={companyType}
        companyAccessUrl={companyAccessUrl}
        productAccessUrl={productAccessUrl}
        menuAccessUrl={menuAccessUrl}
        onUpdate={updateCpgBrokerCompanies}
        showFullRetailerAccessCheckBox={false}
      />
    );
  }
}

ConfigureCpgBrokerCompanyAccess.propTypes = {
  fullCompanyAccess: PropTypes.bool,
  companies: PropTypes.array,
  heading: PropTypes.string,
  companyType: string,
  configuredCompanyIds: PropTypes.array,
  cpgBrokerId: PropTypes.number,
  companyAccessUrl: PropTypes.string,
  productAccessUrl: PropTypes.string,
  menuAccessUrl: PropTypes.string
};

export default ConfigureCpgBrokerCompanyAccess;
