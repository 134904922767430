import React, { useState, useEffect } from "react";
import GroupedNotifications from "./GroupedNotifications";
import { getNotificationPreferences, updateNotificationPreference } from "../api/notificationPreference";
import { groupBy } from "lodash";

function UserNotificationPreference() {
  const [notificationPreferences, setNotificationPreferences] = useState([]);
  const preferences = groupBy(notificationPreferences, datum => datum.notificationType.groupName);

  useEffect(() => {
    getNotificationPreferences().then(data => setNotificationPreferences(data.notificationPreferences));
  }, []);

  function handlePreferenceFrequencyChange(id, value) {
    updateNotificationPreference({ id: id, frequency: value }).then(data =>
      setNotificationPreferences(data.notificationPreferences)
    );
  }

  return (
    <div className="notification-setting-box">
      <p>
        <em>
          This section allows a user to set how often they would like to receive email alerts from the Promio
          application for specific notifications.
        </em>
      </p>
      {Object.keys(preferences).map(key => (
        <GroupedNotifications
          key={key}
          name={key}
          notificationPreferences={preferences[key]}
          preferenceFrequencyChange={handlePreferenceFrequencyChange}
        />
      ))}
    </div>
  );
}

export default UserNotificationPreference;
