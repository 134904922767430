import React, { Component } from "react";
import PropTypes from "prop-types";
import CompanyAccess from "./CompanyAccess";
import { updateCpgBrokerUserCompanies } from "../api/CpgBrokerUserCompanies";
import { formattedCompanies } from "../helpers/CompanyAccess";

class ConfigureCpgBrokerUserCompanyAccess extends Component {
  render() {
    const {
      cpgBrokerUserId,
      fullCompanyAccess,
      companies,
      heading,
      companyType,
      configuredCompanyIds,
      companyAccessUrl,
      productAccessUrl,
      menuAccessUrl
    } = this.props;

    return (
      <CompanyAccess
        id={cpgBrokerUserId}
        fullCompanyAccess={fullCompanyAccess}
        companies={formattedCompanies(companies, configuredCompanyIds)}
        heading={heading}
        companyType={companyType}
        companyAccessUrl={companyAccessUrl}
        productAccessUrl={productAccessUrl}
        menuAccessUrl={menuAccessUrl}
        onUpdate={updateCpgBrokerUserCompanies}
        showFullCompanyAccessCheckBox={true}
      />
    );
  }
}

ConfigureCpgBrokerUserCompanyAccess.propTypes = {
  cpgBrokerUserId: PropTypes.number,
  fullCompanyAccess: PropTypes.bool,
  companies: PropTypes.array,
  heading: PropTypes.string,
  companyType: PropTypes.string,
  configuredCompanyIds: PropTypes.array,
  companyAccessUrl: PropTypes.string,
  productAccessUrl: PropTypes.string,
  menuAccessUrl: PropTypes.string
};

export default ConfigureCpgBrokerUserCompanyAccess;
